import 'knockout.validation';
import * as ko from 'knockout';

import { Texts } from '../Common/Texts';

interface KnockoutValidationRuleDefinitions {
    overAndIncludingCertainAge: KnockoutValidationRuleDefinition;
    underCertainAge: KnockoutValidationRuleDefinition;
    cpr: KnockoutValidationRuleDefinition;
    validCprLength: KnockoutValidationRuleDefinition;
    validCprDate: KnockoutValidationRuleDefinition;
    fullname: KnockoutValidationRuleDefinition;
    phone: KnockoutValidationRuleDefinition;
}

class CprHelper {
    public static cprToDateOfBirth(cpr: string): Date {
        const day = parseInt(cpr.substring(0, 2));
        const month = parseInt(cpr.substring(2, 4));
        const year = parseInt(cpr.substring(4, 6));
        const seventhDigit = parseInt(cpr.substring(6, 7));
        let century = 0;
        switch (seventhDigit) {
            case 0:
            case 1:
            case 2:
            case 3:
                century = 1900;
                break;
            case 4:
            case 9:
                if (year <= 36) {
                    century = 2000;
                } else {
                    century = 1900;
                }
                break;
            case 5:
            case 6:
            case 7:
            case 8:
                if (year <= 57) {
                    century = 2000;
                } else {
                    century = 1800;
                }
                break;
        }
        // months are zero indexed
        return new Date(century + year, month - 1, day);
    }
}

ko.validation.rules.validCprLength = {
    validator: (cpr: string) => {
        if (!cpr) return true;

        const cleanedCPR = cpr.replace(/[-\s]/gi, '');

        if (cleanedCPR.length < 10) {
            return false;
        }

        return true;
    },
    message: Texts.getResource('CprTooShort'),
};

ko.validation.rules.validCprDate = {
    validator: (cpr: string) => {
        if (!cpr) return true;

        const cleanedCPR = cpr.replace(/[-\s]/gi, '');
        if (!cleanedCPR || cleanedCPR.length < 4) return true;

        const day = parseInt(cleanedCPR.substring(0, 2));
        const month = parseInt(cleanedCPR.substring(2, 4));

        if (day > 31) return false;
        if (month > 12) return false;

        return true;
    },
    message: Texts.getResource('InvalidCpr'),
};

ko.validation.rules.underCertainAge = {
    validator: (cpr: string, validate: number) => {
        if (!cpr) return true;

        const cleanedCPR = cpr.replace(/[-\s]/gi, '');
        if (cleanedCPR.length < 7) return true;

        const birthday = CprHelper.cprToDateOfBirth(cleanedCPR);
        const maxAgeYearsAgo = new Date();
        maxAgeYearsAgo.setFullYear(maxAgeYearsAgo.getFullYear() - validate);
        return birthday > maxAgeYearsAgo;
    },
    message: Texts.getResource('CprTooOld'),
};

ko.validation.rules.overAndIncludingCertainAge = {
    validator: (cpr: string, validate: number) => {
        const birthday = CprHelper.cprToDateOfBirth(cpr);
        const minAgeYearsAgo = new Date();
        minAgeYearsAgo.setFullYear(minAgeYearsAgo.getFullYear() - validate);
        return birthday < minAgeYearsAgo;
    },
    message: Texts.getResource('CprTooYoung'),
};

ko.validation.rules.cpr = {
    validator: (cpr: string, validate: boolean) => {
        if (!validate) {
            return true;
        }
        cpr = cpr.replace(/[-\s]/gi, '');
        if (!cpr || cpr.length !== 10) {
            return false;
        }
        let mainInt = 0;
        const factors = [4, 3, 2, 7, 6, 5, 4, 3, 2, 1];
        for (let i = 0; i < cpr.length; i++) {
            const cprDigit = parseInt(cpr.substring(i, i + 1));
            mainInt += cprDigit * factors[i];
        }
        return mainInt % 11 === 0;
    },
    message: Texts.getResource('InvalidCpr'),
};

ko.validation.rules.fullname = {
    validator: (name: string, validate: boolean) => {
        if (!validate) {
            return true;
        }
        return name && name.trim().indexOf(' ') > -1;
    },
    message: Texts.getResource('InvalidName'),
};

ko.validation.rules.phone = {
    validator: (phone: string, validate: boolean) => {
        if (!validate) {
            return true;
        }
        return /^[\+\d]?[\d-.\s()]*$/.test(phone);
    },
    message: Texts.getResource('InvalidPhoneNumber'),
};

ko.validation.rules.petBirthdateNotInTheFuture = {
    validator: (value: Date, validate: boolean) => {
        if (!validate) {
            return true;
        }
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();
        return new Date(currentYear, currentMonth, 25) > value;
    },
    message: Texts.getResource('ErrorDateInTheFuture'),
};

ko.validation.registerExtenders();
